import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { IoClose } from 'react-icons/io5';
import { Radio } from 'lucide-react';
import '.././scss/styles.scss';

const MultiFairAndPopupInfoModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [fiere, setFiere] = useState([]);
  const [popups, setPopups] = useState([]);
  const [hasVisited, setHasVisited] = useState(false);
  const [showReminder, setShowReminder] = useState(false);
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
 

  useEffect(() => {
    const checkVisited = () => {
      const visited = localStorage.getItem('hasVisitedBefore');
      console.log('Visited status:', visited);
      if (!visited) {
        console.log('First visit, opening modal');
        fetchFiere();
        fetchPopups();
        setIsOpen(true);
        localStorage.setItem('hasVisitedBefore', 'true');
      } else {
        console.log('Not first visit, showing reminder');
        setShowReminder(true);
      }
      setHasVisited(!!visited);
    };

    checkVisited();
  }, []);

    const fetchFiere = async () => {
      try {
        const response = await axios.get('https://grec-backend.onrender.com/api/fiere?depth=1');
        console.log('API Response:', response.data);

        const today = new Date();
        const fiereFiltrateEOrdinate = response.data.docs
          .filter(fiera => new Date(fiera.dataFieraInizio) >= today)
          .sort((a, b) => new Date(a.dataFieraInizio) - new Date(b.dataFieraInizio));

        const fiereWithImages = fiereFiltrateEOrdinate.map(fiera => ({
          ...fiera,
          imageUrl: fiera.immagineFiera ? `https://grec-backend.onrender.com/api/media/${fiera.immagineFiera}` : null
        }));

        console.log('Fiere filtrate e ordinate con immagini:', fiereWithImages);
        setFiere(fiereWithImages);
      } catch (error) {
        console.error('Error fetching fiere:', error); 
      }
    };

    


  const fetchPopups = async () => {
    try {
   
      const response = await axios.get('https://grec-backend.onrender.com/api/popup?depth=1');
      const today = new Date();
      const popupsAttivi = response.data.docs
        .filter(popup => new Date(popup.dataPubblicazione) <= today && new Date(popup.dataPopupFine) >= today)
        .sort((a, b) => new Date(b.dataPubblicazione) - new Date(a.dataPubblicazione));


        const popupWithImages = popupsAttivi.map(popup => ({
          ...popup,
          imageUrl: popup.immaginePopup ? `https://grec-backend.onrender.com/api/media/${popup.immaginePopup}` : null
        }));


      setPopups(popupWithImages);
      console.log('Popups fetched:', popupsAttivi);
    } catch (error) {
      console.error('Error fetching popups:', error);
    }
  };




  
  const formatDateRange = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const isItalian = i18n.language === 'it';
    
    const formatDate = (date, options) => {
      return date.toLocaleDateString(isItalian ? 'it-IT' : 'en-US', options);
    };

    if (startDate.getMonth() === endDate.getMonth() && startDate.getFullYear() === endDate.getFullYear()) {
      const monthYear = formatDate(endDate, { month: 'long', year: 'numeric' });
      return isItalian
        ? `dal ${startDate.getDate()} al ${endDate.getDate()} ${monthYear}`
        : `from ${formatDate(startDate, { day: 'numeric' })} to ${formatDate(endDate, { day: 'numeric' })} ${monthYear}`;
    } else {
      return isItalian
        ? `dal ${formatDate(startDate, { day: 'numeric', month: 'long', year: 'numeric' })} al ${formatDate(endDate, { day: 'numeric', month: 'long', year: 'numeric' })}`
        : `from ${formatDate(startDate, { day: 'numeric', month: 'long', year: 'numeric' })} to ${formatDate(endDate, { day: 'numeric', month: 'long', year: 'numeric' })}`;
    }
  };

  const renderPopupContent = (content) => {
    if (!Array.isArray(content)) {
      return null;
    }

    return content.map((node, index) => {
      switch (node.type) {
        case 'h1':
          return <h2 key={index}>{node.children[0].text}</h2>;
        case 'h2':
          return <h3 key={index}>{node.children[0].text}</h3>;
        case 'h3':
          return <h3 key={index}>{node.children[0].text}</h3>;
        case 'paragraph':
          return <p key={index}>{node.children[0].text}</p>;
        // Aggiungi altri casi per gestire altri tipi di nodi se necessario
        default:
          return <p key={index}>{node.children[0].text}</p>;
      }
    });
  };


  const modalVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: {
        duration: 0.3,
        ease: [0.48, 0.15, 0.25, 0.96]
      }
    },
    exit: { 
      opacity: 0,
      transition: {
        duration: 0.2
      }
    }
  };

  const contentVariants = {
    hidden: { y: 50, opacity: 0 },
    visible: { 
      y: 0, 
      opacity: 1,
      transition: {
        type: 'spring',
        stiffness: 300,
        damping: 30
      }
    },
    exit: { 
      y: 50, 
      opacity: 0,
      transition: {
        duration: 0.2
      }
    }
  };

  const handleClose = () => {
    console.log('Closing modal');
    setIsOpen(false);
    setShowReminder(true);
  };

  const handleOpen = () => {
    console.log('Opening modal');
    if (hasVisited) {
      fetchFiere();
      fetchPopups();
    }
    setIsOpen(true);
    setShowReminder(false);
  };

  console.log('Current state - isOpen:', isOpen, 'showReminder:', showReminder);

  return (
    <>
      <AnimatePresence>
        {isOpen && (
          <motion.div 
            className="modal-combined"
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={modalVariants}
          >
            <motion.div 
              className="modal-content-combined"
              variants={contentVariants}
            >
              <motion.button 
                className="close-button-combined"
                onClick={handleClose}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                <IoClose />
              </motion.button>
              
              {fiere.length > 0 && (
                <div className="fiere-section-combined">
                  <h2 className="section-title-combined">Prossime Fiere</h2>
                  {fiere.map((fiera) => {
                    const dateRange = formatDateRange(fiera.dataFieraInizio, fiera.dataFieraFine);
                    return (
                      <div key={fiera.id} className="fiera-item-combined">
                        <div className="fiera-info-combined">
                          <h3>{fiera.titoloDellaFiera}</h3>
                          <span>{fiera.stand}</span>
                          <p>{dateRange}</p>
                        </div>
                      {fiera.imageUrl && (
  <>
    {console.log('Fiera image URL:',fiera.imageUrl)}
    <img 
      src={fiera.imageUrl}
      alt={fiera.titoloDellaFiera}
      className="fiera-image-combined"
    />
  </>
)}
                      </div>
                    );
                  })}
                </div>
              )}
           
              {popups.length > 0 && (
                <div className="popup-section-combined">
                  <h2 className="section-title-combined">{currentLanguage === "it" ? "Annunci speciali" : "Special announcements"}</h2>
                  {popups.map((popup) => (
                    <div key={popup.id} className="popup-item-combined">
                   <div>  
                    
                     <h3>{currentLanguage === "it" ? popup.titoloDelPopup : popup.titoloDelPopupEn}</h3>
                      <div className="popup-content-combined">
                      {currentLanguage === "it" ? renderPopupContent(popup.contanutoPopup) : renderPopupContent(popup.contanutoPopupEN)}

                      </div>
                      
                      
                      </div>
                      {popup.imageUrl && (
                        <img 
                          src={popup.imageUrl}
                          alt={popup.titoloDelPopup}
                          className="popup-image-combined"
                        />
                      )}
                    </div>
                  ))}
                </div>
              )}
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
   
      <AnimatePresence>
        {showReminder && (
          <motion.div
            className="reminder-icon"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            transition={{ duration: 0.3 }}
            onClick={handleOpen}
          >
            <Radio size={24} />
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default MultiFairAndPopupInfoModal;