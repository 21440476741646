import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import "@maptiler/sdk/dist/maptiler-sdk.css";
import './scss/styles.scss';
import { initMatomo } from './components/matomo';
import CreatedByFirewall from "./CreatedbyFirewall";
import data from "./noSQL/data";
import Disclaimer from './pages/Disclaimer';
import InfoModal from './components/InfoModal';

// COMPONENTI
import Footer from "./pages/components/Foooter";
import Navbar from "./pages/components/Navbar";
import BottomNavbar from "./pages/components/BottomNavbar";
import ScrollToTop from './pages/components/ScrollToTop';
import SEOHelmet from './components/SEOHelmet';

// PAGINE
import Homepage from "./pages/Homepage";
import AboutUs from "./pages/AboutUs";
import Product from "./pages/Prodotti";
import ProductDetails from "./pages/ProductDetails";
import CategoryProducts from "./pages/CategoryProducts";
import Applicazioni from "./pages/Applicazioni";
import Download from "./pages/Download";
import Gallery from "./pages/Gallery";
import Contatti from "./pages/Contatti";
import Certifications from "./pages/Certifications";
import Lavora from "./pages/Lavora";
import Fiere from "./pages/Fiere";

const backgroundMap = {
  '/': 'linear-gradient(90deg,  #E44949, #1E1E1E)',
  '/chi-siamo': 'linear-gradient(90deg, rgb(67 67 67), rgb(30, 30, 30))',
  '/prodotti': 'linear-gradient(90deg, rgb(67 67 67), rgb(30, 30, 30))',
  '/applicazioni': 'linear-gradient(90deg, rgb(67 67 67), rgb(30, 30, 30))',
  '/download': 'linear-gradient(90deg, rgb(67 67 67), rgb(30, 30, 30))',
  '/gallery': 'linear-gradient(90deg, rgb(67 67 67), rgb(30, 30, 30))',
  '/contatti': 'linear-gradient(90deg, rgb(67 67 67), rgb(30, 30, 30))',
  '/disclaimer': 'linear-gradient(90deg, rgb(67 67 67), rgb(30, 30, 30))',
  '/lavora-con-noi': 'linear-gradient(90deg, rgb(67 67 67), rgb(30, 30, 30))',
  '/categoria/agitatori-verticali': 'linear-gradient(90deg, rgb(67 67 67), rgb(30, 30, 30))',
  '/categoria/agitatori-laterali': 'linear-gradient(90deg, rgb(67 67 67), rgb(30, 30, 30))',
  '/categoria/agitatori-portatili': 'linear-gradient(90deg, rgb(67 67 67), rgb(30, 30, 30))',
  '/categoria/in-linea': 'linear-gradient(90deg, rgb(67 67 67), rgb(30, 30, 30))',
  '/categoria/giranti': 'linear-gradient(90deg, rgb(67 67 67), rgb(30, 30, 30))',
  '/certifications': 'linear-gradient(90deg, rgb(67 67 67), rgb(30, 30, 30))',
};

function getBackground(pathname) {
  if (pathname.startsWith('/prodotti/')) {
    return backgroundMap['/prodotti'] || 'linear-gradient(90deg, rgb(67 67 67), rgb(30, 30, 30))';
  }
  return backgroundMap[pathname] || 'linear-gradient(90deg,  #E44949, #1E1E1E)';
}

const SEOWrapper = ({ children, title, description, keywords, canonicalUrl, ogImage, ogUrl, twitterImage }) => (
  <>
    <SEOHelmet 
      title={title}
      description={description}
      keywords={keywords}
      canonicalUrl={canonicalUrl}
      ogImage={ogImage}
      ogUrl={ogUrl}
      twitterImage={twitterImage}
    />
    {children}
  </>
);

function AppContent() {
  const location = useLocation();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const background = getBackground(location.pathname);
    document.body.style.background = background;
  }, [location]);

  useEffect(() => {
    initMatomo();
  }, []);

  useEffect(() => {
    let lastScrollY = window.pageYOffset;

    const handleScroll = () => {
      const currentScrollY = window.pageYOffset;
      if (currentScrollY > lastScrollY) {
        document.body.classList.add('scroll-down');
        document.body.classList.remove('scroll-up');
      } else {
        document.body.classList.add('scroll-up');
        document.body.classList.remove('scroll-down');
      }
      lastScrollY = currentScrollY;
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <ScrollToTop />
      <Navbar />
      <BottomNavbar/>
      <InfoModal/>
      <div className="body--max--control">
      <CreatedByFirewall/>
        <Routes>
          <Route path="/" element={
            <SEOWrapper 
              title={t('home.title')}
              description={t('home.description')}
              keywords={t('home.keywords')}
              canonicalUrl={`https://www.grec.it/${i18n.language}`}
              ogImage="https://www.grec.it/og-image.jpg"
              ogUrl={`https://www.grec.it/${i18n.language}`}
            >
              <Homepage />
            </SEOWrapper>
          } />
          <Route path="/chi-siamo" element={
            <SEOWrapper 
              title={t('aboutUs.title')}
              description={t('aboutUs.description')}
              keywords={t('aboutUs.keywords')}
              canonicalUrl={`https://www.grec.it/${i18n.language}/chi-siamo`}
              ogImage="https://www.grec.it/about-us-og-image.jpg"
              ogUrl={`https://www.grec.it/${i18n.language}/chi-siamo`}
            >
              <AboutUs />
            </SEOWrapper>
          } />
          <Route path="/prodotti" element={
            <SEOWrapper 
              title={t('products.title')}
              description={t('products.description')}
              keywords={t('products.keywords')}
              canonicalUrl={`https://www.grec.it/${i18n.language}/prodotti`}
              ogImage="https://www.grec.it/products-og-image.jpg"
              ogUrl={`https://www.grec.it/${i18n.language}/prodotti`}
            >
              <Product data={data} />
            </SEOWrapper>
          } />
          <Route path="/prodotti/:route" element={<ProductDetails />} />
          <Route path="/it/prodotti/agitatori-laterali/alf" element={<ProductDetails />} />
          <Route path="/applicazioni" element={
            <SEOWrapper 
              title={t('applications.title')}
              description={t('applications.description')}
              keywords={t('applications.keywords')}
              canonicalUrl={`https://www.grec.it/${i18n.language}/applicazioni`}
              ogImage="https://www.grec.it/applications-og-image.jpg"
              ogUrl={`https://www.grec.it/${i18n.language}/applicazioni`}
            >
              <Applicazioni />
            </SEOWrapper>
          } />
          <Route path="/download" element={
            <SEOWrapper 
              title={t('download.title')}
              description={t('download.description')}
              keywords={t('download.keywords')}
              canonicalUrl={`https://www.grec.it/${i18n.language}/download`}
              ogImage="https://www.grec.it/download-og-image.jpg"
              ogUrl={`https://www.grec.it/${i18n.language}/download`}
            >
              <Download />
            </SEOWrapper>
          } />
          <Route path="/gallery" element={
            <SEOWrapper 
              title={t('gallery.title')}
              description={t('gallery.description')}
              keywords={t('gallery.keywords')}
              canonicalUrl={`https://www.grec.it/${i18n.language}/gallery`}
              ogImage="https://www.grec.it/gallery-og-image.jpg"
              ogUrl={`https://www.grec.it/${i18n.language}/gallery`}
            >
              <Gallery />
            </SEOWrapper>
          } />
          <Route path="/it/gallery" element={
            <SEOWrapper 
              title={t('gallery.title')}
              description={t('gallery.description')}
              keywords={t('gallery.keywords')}
              canonicalUrl="https://www.grec.it/it/gallery"
              ogImage="https://www.grec.it/gallery-og-image.jpg"
              ogUrl="https://www.grec.it/it/gallery"
            >
              <Gallery />
            </SEOWrapper>
          } />
          <Route path="/contatti" element={
            <SEOWrapper 
              title={t('contacts.title')}
              description={t('contacts.description')}
              keywords={t('contacts.keywords')}
              canonicalUrl={`https://www.grec.it/${i18n.language}/contatti`}
              ogImage="https://www.grec.it/contacts-og-image.jpg"
              ogUrl={`https://www.grec.it/${i18n.language}/contatti`}
            >
              <Contatti />
            </SEOWrapper>
          } />
          <Route path="/it/contatti" element={
            <SEOWrapper 
              title={t('contacts.title')}
              description={t('contacts.description')}
              keywords={t('contacts.keywords')}
              canonicalUrl="https://www.grec.it/it/contatti"
              ogImage="https://www.grec.it/contacts-og-image.jpg"
              ogUrl="https://www.grec.it/it/contatti"
            >
              <Contatti />
            </SEOWrapper>
          } />
          <Route path="/disclaimer" element={
            <SEOWrapper 
              title={t('disclaimer.title')}
              description={t('disclaimer.description')}
              keywords={t('disclaimer.keywords')}
              canonicalUrl={`https://www.grec.it/${i18n.language}/disclaimer`}
              ogImage="https://www.grec.it/disclaimer-og-image.jpg"
              ogUrl={`https://www.grec.it/${i18n.language}/disclaimer`}
            >
              <Disclaimer />
            </SEOWrapper>
          } />
          <Route path="/certifications" element={
            <SEOWrapper 
              title={t('certifications.title')}
              description={t('certifications.description')}
              keywords={t('certifications.keywords')}
              canonicalUrl={`https://www.grec.it/${i18n.language}/certifications`}
              ogImage="https://www.grec.it/certifications-og-image.jpg"
              ogUrl={`https://www.grec.it/${i18n.language}/certifications`}
            >
              <Certifications />
            </SEOWrapper>
          } />
           <Route path="/fiere" element={
            <SEOWrapper 
              title={t('fiere.title')}
              description={t('certfiereifications.description')}
              keywords={t('fiere.keywords')}
              canonicalUrl={`https://www.grec.it/${i18n.language}/fiere`}
              ogImage="https://www.grec.it/certifications-og-image.jpg"
              ogUrl={`https://www.grec.it/${i18n.language}/fiere`}
            >
              <Fiere />
            </SEOWrapper>
          } />
          <Route path="/lavora-con-noi" element={
            <SEOWrapper 
              title={t('workWithUs.title')}
              description={t('workWithUs.description')}
              keywords={t('workWithUs.keywords')}
              canonicalUrl={`https://www.grec.it/${i18n.language}/lavora-con-noi`}
              ogImage="https://www.grec.it/work-with-us-og-image.jpg"
              ogUrl={`https://www.grec.it/${i18n.language}/lavora-con-noi`}
            >
              <Lavora />
            </SEOWrapper>
          } />
          <Route path="/categoria/:category" element={<CategoryProducts />} />

          {/* ROUTE PER SEO HACKING */}
          <Route path="*" element={<Homepage />} />
          <Route path="/en/component/content/category/2-uncategorised" element={<Homepage />} />
          <Route path="/it/14-pages" element={<Download />} />
          <Route path="/it/14-pages" element={<Download/>} />
          <Route path="/it/download/14-pages" element={<Download/>} />
          <Route 
            path="it/prodotti/agitatori-verticali/avs/15-agitatori-verticali" 
            element={<Navigate to="/categoria/agitatori-verticali" replace />} 
          />
          <Route 
            path="/it/prodotti/agitatori-verticali/avs/15-agitatori-verticali" 
            element={<Navigate to="/categoria/agitatori-verticali" replace />} 
          />
        </Routes>
      </div>
      <Footer />
    </>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;