import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

export default function FiereList() {
  const [fiere, setFiere] = useState([]);
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  useEffect(() => {
    const fetchFiere = async () => {
      try {
        const response = await axios.get('https://grec-backend.onrender.com/api/fiere?depth=1');
        console.log('API Response:', response.data);

        // Ordina tutte le fiere in ordine decrescente per `dataFieraInizio`
        const fiereOrdinate = response.data.docs.sort(
          (a, b) => new Date(b.dataFieraInizio) - new Date(a.dataFieraInizio)
        );

        const fiereWithImages = fiereOrdinate.map(fiera => ({
          ...fiera,
          imageUrl: fiera.immagineFiera ? `https://grec-backend.onrender.com/api/media/${fiera.immagineFiera}` : null
        }));

        console.log('Fiere ordinate con immagini:', fiereWithImages);
        setFiere(fiereWithImages);
      } catch (error) {
        console.error('Error fetching fiere:', error);
      }
    };

    fetchFiere();
  }, []);

  const formatDateRange = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);

    const isItalian = i18n.language === 'it';

    const formatDate = (date, options) => {
      return date.toLocaleDateString(isItalian ? 'it-IT' : 'en-US', options);
    };

    if (startDate.getMonth() === endDate.getMonth() && startDate.getFullYear() === endDate.getFullYear()) {
      const monthYear = formatDate(endDate, { month: 'long', year: 'numeric' });
      if (isItalian) {
        return `dal ${startDate.getDate()} al ${endDate.getDate()} ${monthYear}`;
      } else {
        return `from ${formatDate(startDate, { day: 'numeric' })} to ${formatDate(endDate, { day: 'numeric' })} ${monthYear}`;
      }
    } else {
      if (isItalian) {
        return `dal ${formatDate(startDate, { day: 'numeric', month: 'long', year: 'numeric' })} al ${formatDate(endDate, { day: 'numeric', month: 'long', year: 'numeric' })}`;
      } else {
        return `from ${formatDate(startDate, { day: 'numeric', month: 'long', year: 'numeric' })} to ${formatDate(endDate, { day: 'numeric', month: 'long', year: 'numeric' })}`;
      }
    }
  };

  return (
    <>
      <div className="fiere-section full-width">
        <h1>{currentLanguage === "it" ? "Fiere ed eventi" : "Exhibition and Events"}</h1>
        {fiere.map((fiera) => {
          const dateRange = formatDateRange(fiera.dataFieraInizio, fiera.dataFieraFine);
          return (
            <div key={fiera.id} className="fiere-item">
              <div className="fiere-info">
                <h2>{fiera.titoloDellaFiera}</h2>
                <span>{fiera.stand}</span>
                <p>{dateRange}</p>
              </div>
              {fiera.imageUrl && (
                <img
                  src={fiera.imageUrl}
                  alt={fiera.titoloDellaFiera}
                  className="fiere-image"
                  onError={(e) => {
                    console.error('Image failed to load:', fiera.imageUrl);
                    e.target.onerror = null;
                    e.target.src = '/placeholder.svg?height=200&width=300';
                  }}
                />
              )}
            </div>
          );
        })}
      </div>
    </>
  );
}
